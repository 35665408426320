import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeiyakuHojinListType, KyotenListT, RoleListType, SystemListType } from '../toppage-types';
// import { environment } from 'projects/mamezo/src/environments/environment';

// const logger = environment.logger;

// モーダルを出すときに背景のスクロールをさせないようにする
let scrollPosition: number = 0;
var fixScroll = () => {
  scrollPosition = window.scrollY;
  Object.assign(document.body.style, {
    overflow: 'hidden',
    position: 'fixed',
    top: -scrollPosition + 'px',
    width: '100%',
  });
};
var releaseScroll = () => {
  Object.assign(document.body.style, {
    overflow: '',
    position: '',
    top: '',
    width: '',
  });
  window.scrollTo(0, scrollPosition);
};

@Component({
  selector: 'search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent {
  @Input() searchForm!: FormGroup;
  @Input() mailGroupChoiceList: any; // 本当はMailGroupChoiceクラス
  @Input() keiyakuHojinList: KeiyakuHojinListType = [];
  @Input() kyotenList: KyotenListT = [];
  @Input() roleList: RoleListType = [];
  // @Input() displayColConfig = {jusho: true, tantosha: true, role: false, lastUpdated: true, system1: false, system2: false, system3: false};
  @Input() displayColConfig: Record<string, boolean> = {jusho: true, tantosha: true, role: false, lastUpdated: true, system1: true, system2: true, system3: true};
  @Input() isOpenVisibleColumn = false;
  @Input() systemList: SystemListType = [];

  @Output() doSearch = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();
  @Output() changeDisplayCol = new EventEmitter();
  @Output() toggleVisibleCol = new EventEmitter();

  kashidashisaki = [
    {id: "INCLUDE", name: "含む"},
    {id: "NOT INCLUDE", name: "含まない"},
    {id: "ONLY", name: "貸出先のみ"},
  ];

  // 絞込み検索の開閉
  isOpenCondition = false;
  toggleCondition() {
    this.isOpenCondition = !this.isOpenCondition;
  }
  closeCondition() {
    this.isOpenCondition = false;
  }

  // 検索結果表示項目の開閉
  toggleVisibleColumn() {
    this.isOpenVisibleColumn = !this.isOpenVisibleColumn;
    this.toggleVisibleCol.emit(this.isOpenVisibleColumn);
  }
  closeVisibleColumn() {
    this.isOpenVisibleColumn = false;
    this.toggleVisibleCol.emit(this.isOpenVisibleColumn);
  }

  // 検索条件：契約法人コンボ
  isShowHojin = false;
  isHojinFilter = true;
  showHojin() {
    this.isShowHojin = true;
  }
  toggleHojin($event : MouseEvent) {
    this.isHojinFilter = !this._ifOnRightEdge($event) && !this.isShowHojin;
    this.isShowHojin = !this.isShowHojin;
    if (this.isShowHojin) {
      (document.getElementById("search_form_hojin") as HTMLInputElement).select();
    }
  }

  blurHojin() {
    // 入力中の文字列で検索して１件に決まる場合のみセット
    const text = this.searchForm.controls.keiyakuHojinNm.value;
    const candidate = this.keiyakuHojinList.filter(hojin => text != "" && (hojin.cd + "：" + hojin.nm).indexOf(text) > -1);
    if (candidate.length == 1) {
      this._setHojinValue(candidate[0].cd, candidate[0].cd + "：" + candidate[0].nm);
    } else {
      this._setHojinValue("", "");
      this.keiyakuHojinList.forEach(hojin => hojin.is_hide = false);
    }
    this.isShowHojin = false;
  }

  onselectHojin(cd: string, nm: string) {
    this._setHojinValue(cd, nm);
    this.isShowHojin = false;
  }

  _setHojinValue(hojinId: string, hojinNm: string) {
    this.searchForm.controls.keiyakuHojin.setValue(hojinId);
    this.searchForm.controls.keiyakuHojinCd.setValue(hojinId);
    this.searchForm.controls.keiyakuHojinNm.setValue(hojinNm);

    this._clearKyotenValueIfDifferentHojin(hojinId);
    if (this.getKyotenCandidate().length == 1) {
      const kyoten = this.getKyotenCandidate()[0];
      this._setKyotenValue(kyoten.cd, kyoten.cd + "：" + kyoten.nm);
    }
  }
  /** 選択中の拠点が、選択した法人でなければ、クリアする */
  _clearKyotenValueIfDifferentHojin(hojinId: string) {
    if (!hojinId) return;
    if (!this.searchForm.controls.kyoten.value) return;
    const selectedKyotenHojin = this.kyotenList.find(kyoten => kyoten.cd == this.searchForm.controls.kyoten.value)?.x_keiyaku_hojin_cd;
    if (selectedKyotenHojin && selectedKyotenHojin != this.searchForm.controls.keiyakuHojin.value) {
      this._setKyotenValue("", "");
    }
  }

  searchHojinValue() {
    let text = this.searchForm.controls.keiyakuHojinNm.value;
    this.keiyakuHojinList.forEach(hojin => {
      hojin.is_hide = text != "" && (hojin.cd + "：" + hojin.nm).indexOf(text) == -1;
    })
  }
  getHojinCandidate() {
    return this.keiyakuHojinList
          .filter(hojin => this.isHojinFilter ? !hojin.is_hide : true);
  }
  // 検索条件：拠点コンボ
  /** 拠点の選択肢を表示する */
  isShowKyoten = false;
  /** 入力値で拠点の選択肢を絞り込む */
  isKyotenFilter = true;
  showKyoten() {
    this.isShowKyoten = true;
  }
  toggleKyoten($event : MouseEvent) {
    this.isKyotenFilter = !this._ifOnRightEdge($event) && !this.isShowKyoten;
    this.isShowKyoten = !this.isShowKyoten;
    if (this.isShowKyoten) {
      (document.getElementById("search_form_kyoten") as HTMLInputElement).select();
    }
  }

  blurKyoten() {
    // 入力中の文字列で検索して１件に決まる場合のみセット
    const text = this.searchForm.controls.kyotenNm.value;
    const candidate = this.kyotenList.filter(kyoten => text != "" && (kyoten.cd + "：" + kyoten.nm).indexOf(text) > -1);
    if (candidate.length == 1) {
      this._setKyotenValue(candidate[0].cd, candidate[0].cd + "：" + candidate[0].nm);
    } else {
      this._setKyotenValue("", "");
      this.kyotenList.forEach(kyoten => kyoten.is_hide = false);
    }
    this.isShowKyoten = false;
  }

  onselectKyotenValue(cd: string, nm: string) {
    this._setKyotenValue(cd, nm);
    this.isShowKyoten = false;
  }
  _setKyotenValue(kyotenId: string, kyotenNm: string) {
    this.searchForm.controls.kyoten.setValue(kyotenId);
    this.searchForm.controls.kyotenCd.setValue(kyotenId);
    this.searchForm.controls.kyotenNm.setValue(kyotenNm);
    this._setHojinValueToKyotenHojin(kyotenId);
  }
  _setHojinValueToKyotenHojin(kyotenId: string) {
    const kyoten = this.kyotenList.find(kyoten => kyoten.cd == kyotenId);
    if (!kyoten) return;
    if (this.searchForm.controls.keiyakuHojin.value != kyoten.x_keiyaku_hojin_cd) {
      const hojin = this.keiyakuHojinList.find(hojin => hojin.cd == kyoten.x_keiyaku_hojin_cd);
      if (!hojin) return;
      this._setHojinValue(hojin.cd, hojin.cd + "：" + hojin.nm);
    }
    
  }

  searchKyotenValue() {
    const text = this.searchForm.controls.kyotenNm.value;
    this.kyotenList.forEach(kyoten => {
      kyoten.is_hide = text != "" && (kyoten.cd + "：" + kyoten.nm).indexOf(text) == -1;
    })
  }

  getKyotenCandidate() {
    const keiyakuHojinCd = this.searchForm.controls.keiyakuHojin.value;
    return this.kyotenList
          .filter(kyoten => this.isKyotenFilter ? !kyoten.is_hide : true)
          .filter(kyoten => !keiyakuHojinCd || kyoten.x_keiyaku_hojin_cd == keiyakuHojinCd );
  }
  getKyotenPlaceholder() {
    return this.getKyotenCandidate().length <= 200 ? '選択してください' : '200件を超えたため虫眼鏡ボタンより選択してください';
  }

  // 検索条件：ロールコンボ
  // isShowRole = false;
  // showRole() {
  //   this.isShowRole = true;
  //   this.searchRoleValue();
  // }
  // toggleRole() {
  //   this.isShowRole = !this.isShowRole;
  // }

  // blurRole() {
  //   // 入力中の文字列で検索して１件に決まる場合のみセット
  //   const text = this.searchForm.controls.roleNm.value;
  //   let target = this.roleList.find(role => role.role_nm == text);
  //   if (!target) {
  //     const candidate = this.roleList.filter(role => text != "" && role.role_nm.indexOf(text) > -1);
  //     if (candidate.length == 1) {
  //       target = candidate[0];
  //     }
  //   }

  //   if (target) {
  //     this.setRoleValue(target.role_id, target.role_nm);
  //   } else {
  //     this.setRoleValue("", "");
  //     this.roleList.forEach(role => role.is_hide = false);
  //   }
  //   this.isShowRole = false;
  // }

  // setRoleValue(role_id: string, role_nm: string) {
  //   this.searchForm.controls.roleId.setValue(role_id);
  //   this.searchForm.controls.roleNm.setValue(role_nm);
  //   logger.log(this.searchForm.controls.roleId.value)
  //   this.isShowRole = false;
  // }

  // searchRoleValue() {
  //   const text = this.searchForm.controls.roleNm.value;
  //   this.roleList.forEach(role => {
  //     role.is_hide = text != "" && role.role_nm.indexOf(text) == -1;
  //   })
  // }

  // 契約法人検索ダイアログ
  isOpenHojinSearch: boolean = false;
  openHojinSearchModal = () => {
    this.isOpenHojinSearch = true;
    fixScroll();
  }
  closeHojinSearchModal = () => {
    releaseScroll();
    this.isOpenHojinSearch = false;
  }
  onHojinSelect(value: string) {
    try {
      const hojin = JSON.parse(value);
      this._setHojinValue(hojin.x_keiyaku_hojin_cd, `${hojin.x_keiyaku_hojin_cd}：${hojin.keiyaku_hojin_nm}`);
    } catch(e) {
      console.error(e);
    }
    this.closeHojinSearchModal();
  }
  // 拠点検索ダイアログ
  isOpenKyotenSearch: boolean = false;
  openKyotenSearchModal = () => {
    this.isOpenKyotenSearch = true;
    fixScroll();
  }
  closeKyotenSearchModal = () => {
    releaseScroll();
    this.isOpenKyotenSearch = false;
  }
  onKyotenSelect(value: string) {
    try {
      const kyoten = JSON.parse(value);
      this._setKyotenValue(kyoten.kyoten_cd, `${kyoten.kyoten_cd}：${kyoten.kyoten_nm}`);
    } catch(e) {
      console.error(e);
    }
    this.closeKyotenSearchModal();
  }

  /** 検索結果表示項目の変更 */
  onchangeDisplayColConfig(key: string) { // 'jusho' | 'tantosha' | 'role' | 'lastUpdated'
    this.displayColConfig[key] = !this.displayColConfig[key];
    this.changeDisplayCol.emit(this.displayColConfig);
  }
  initDisplayColConfig() {
    // this.displayColConfig['jusho'] = true;
    // this.displayColConfig['tantosha'] = true;
    // this.displayColConfig['role'] = true;
    // this.displayColConfig['lastUpdated'] = true;
    for(let key in this.displayColConfig) {
      this.displayColConfig[key] = true;
    }
    this.changeDisplayCol.emit(this.displayColConfig);
  }

  onsubmitEvent() {
    this.doSearch.emit();
    return false;
  }

  onmousemoveCombo($event: MouseEvent) {
    const targetElement = $event.target as HTMLElement;
    // const location = targetElement.getBoundingClientRect();
    // const elementRight = location.left + location.width;
    // if (!elementRight) return;
    // const eventX = $event.x;
    // if (!eventX) return;
    if (this._ifOnRightEdge($event)) {
      targetElement.classList.add("on-drop");
    } else {
      targetElement.classList.remove("on-drop");
    }
  }
  onmouseoutCombo($event: MouseEvent) {
    const targetElement = $event.target as HTMLElement;
    targetElement.classList.remove("on-drop");
  }

  _ifOnRightEdge($event: MouseEvent) : boolean {
    const targetElement = $event.target as HTMLElement;
    const location = targetElement.getBoundingClientRect();
    const elementRight = location.left + location.width;
    if (!elementRight) return false;
    const eventX = $event.x;
    if (!eventX) return false;
    return elementRight - 28 < eventX;
  }
}
