<div>
  <form [formGroup]="searchForm" onsubmit="return false;" class="ng-pristine ng-valid ng-touched ng-submitted search-section">
    <div pcwidthtype="min" class="dev-pc div-card pc-min-width">
      <div class="dev-pc div-card-body">
        <div id="searchConditionHeader" class="dev-pc div-card-title">
          <div class="row">
            <div class="d-flex flex-row">
              <span>絞込み検索</span>
              <button type="button" class="dev-pc btn-accordion collapsed" id="searchCondition_btn" [attr.aria-expanded]="isOpenCondition" (click)="toggleCondition()"></button>
            </div>
          </div>
        </div>

        <hr class="title-hr">

        <!-- 絞込み検索 -->
        <div  id="searchCondition_detail" class="collapse dev-pc div-card-body-sub" [attr.style]="isOpenCondition ? 'display: block;' : 'display: none;'">
          <div class="row">
            <div class="col-lg-4">
              <!-- メール受信区分 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>メール受信区分</label>
                  <div class="div-item-title-required"></div>
                  <div class="div-tooltip" title="選択した区分を受信設定している拠点に絞り込まれます。"></div>
                </div>
                <div class="col-lg-7 dev-pc div-item-input">
                  <div>
                    <select formControlName="mailGroup" name="mailGroup" class="ng-pristine ng-valid dev-pc form-select ng-touched">
                      <option value="" class="status-opt-group"></option>
                      <ng-container *ngFor="let obj of mailGroupChoiceList">
                        <option value="{{ obj.key }}" [attr.class]="obj.isGroup ?  'status-opt-group' : 'bg-white'">{{ obj.isGroup ? "" : "&nbsp;&nbsp;" }}{{ obj.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <!-- 契約法人 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>契約法人</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-7 dev-pc div-item-input has-right-btn">
                  <div class="dropdown-custom-wrap">
                    <div ngbdropdown="" class="xfw-select-box position-static dropdown">
                      <input formControlName="keiyakuHojinNm" ngbdropdowntoggle="" id="search_form_hojin" type="text" data-bs-toggle="dropdown" autocomplete="off"
                        class="dropdown-toggle form-suggest-select ng-untouched ng-pristine ng-valid dev-pc form-select" name="keiyakuHojin"
                        placeholder="選択してください" [attr.aria-expanded]="isShowHojin"
                        (click)="toggleHojin($event)" (blur)="blurHojin()"
                        (keyup)="searchHojinValue()" (mouseup)="searchHojinValue()"
                        (mousemove)="onmousemoveCombo($event)" (mouseout)="onmouseoutCombo($event)">
                      <div ngbdropdownmenu="" class="dropdown-menu" [class.show]="isShowHojin" [class.close]="!isShowHojin">
                        <ng-container *ngFor="let obj of getHojinCandidate()">
                          <div>
                            <button ngbdropdownitem="" type="button" class="dropdown-item form-suggest-option form-suggest-option-pc"
                              (click)="onselectHojin(obj.cd, obj.cd + '：' + obj.nm)"
                              (mousedown)="$event.preventDefault()">{{ obj.cd }}：{{ obj.nm }}</button>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-1 dev-pc div-assist-button">
                  <button type="button" class="dev-pc btn btn-sm btn-standard customModal" data-target="corporationModal_btn" (click)="openHojinSearchModal()">
                    <i class="bi bi-search ml-lg-0"></i>
                  </button>
                </div>
              </div>

              <!-- 拠点 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>拠点</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-7 dev-pc div-item-input has-right-btn">
                  <div class="dropdown-custom-wrap">
                    <div ngbdropdown="" class="xfw-select-box position-static dropdown">
                      <input formControlName="kyotenNm" ngbdropdowntoggle="" id="search_form_kyoten" type="text" data-bs-toggle="dropdown" autocomplete="off"
                        class="dropdown-toggle form-suggest-select ng-untouched ng-pristine ng-valid dev-pc form-select" name="keiyakuHojin"
                        [placeholder]="getKyotenPlaceholder()" [attr.aria-expanded]="isShowKyoten"
                        (click)="toggleKyoten($event)" (blur)="blurKyoten()"
                        (keyup)="searchKyotenValue()" (mouseup)="searchKyotenValue()"
                        (mousemove)="onmousemoveCombo($event)" (mouseout)="onmouseoutCombo($event)">
                      <div ngbdropdownmenu="" class="dropdown-menu" [attr.style]="isShowKyoten ? 'display: block; max-height: 400px; overflow-y: auto;' : 'display: none;'">
                        <ng-container *ngIf="getKyotenCandidate().length <= 200">
                          <ng-container *ngFor="let obj of getKyotenCandidate()">
                            <div>
                              <button ngbdropdownitem="" type="button" class="dropdown-item form-suggest-option form-suggest-option-pc"
                              [title]="obj.cd + '：' + obj.nm"
                              (click)="onselectKyotenValue(obj.cd, obj.cd + '：' + obj.nm)"
                              (mousedown)="$event.preventDefault()">{{ obj.cd }}：{{ obj.nm }}</button>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-1 dev-pc div-assist-button">
                  <button type="button" class="dev-pc btn btn-sm btn-standard customModal" data-target="baseModal_btn" (click)="openKyotenSearchModal()">
                    <i class="bi bi-search ml-lg-0"></i>
                  </button>
                </div>
              </div>

              <!-- 拠点名称 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>拠点名称</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-7">
                  <div class="row">
                    <div class="col-lg-12 dev-pc div-item-input">
                      <input type="text" formControlName="kyotenName" maxlength="25" name="kyotenName" class="ng-untouched ng-pristine ng-valid dev-pc form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <!-- 貸出先表示 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>貸出先表示</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-8 dev-pc div-item-input div-item-input input-list-h">
                  <ng-container *ngFor="let obj of kashidashisaki">
                    <label>
                      <input type="radio" formControlName="kashidashisaki" [id]="'kashidashisaki'+obj.id" name="kashidashisaki" class="ng-untouched ng-pristine ng-valid dev-pc form-check-input" [value]="obj.id">
                      <label class="me-3" [for]="'kashidashisaki'+obj.id">{{ obj.name }}</label>
                    </label>
                  </ng-container>
                </div>
              </div>

              <!-- 拠点住所 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>住所</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-8 dev-pc div-item-input" style="padding-left: 0;">
                  <input type="text" formControlName="jusho" name="jusho" class="ng-untouched ng-pristine ng-valid dev-pc form-control">
                </div>
              </div>

              <!-- ロール -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label>ロール</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-8 dev-pc div-item-input" style="padding-left: 0;">
                  <div>
                    <select formControlName="roleId" name="roleId" class="ng-pristine ng-valid dev-pc form-select ng-touched">
                      <option value=""></option>
                      <ng-container *ngFor="let obj of roleList">
                        <option value="{{ obj.role_id }}">{{ obj.role_nm }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <!-- 拠点担当者 -->
              <div class="row">
                <div class="col-lg-4 div-column-resize-plus dev-pc div-item-title" style="padding-right: 17px;">
                  <label class="px-0">拠点担当者</label>
                  <div class="div-item-title-required"></div>
                </div>
                <div class="col-lg-8 dev-pc div-item-input">
                  <input type="text" formControlName="kyotenTantosha" maxlength="25" name="kyotenTantosha" class="ng-untouched ng-pristine ng-valid dev-pc form-control">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="searchConditionHeader" class="dev-pc div-card-title">
          <div class="row">
            <div class="d-flex flex-row">
              <span>検索結果表示項目</span>
              <button type="button" class="dev-pc btn-accordion collapsed" id="searchCondition02_btn" [attr.aria-expanded]="isOpenVisibleColumn" (click)="toggleVisibleColumn()"></button>
            </div>
          </div>
        </div>

        <hr class="title-hr">

        <div id="searchCondition02_detail" class="collapse dev-pc div-card-body-sub" [attr.style]="isOpenVisibleColumn ? 'display: block;' : 'display: none;'">
          <div class="row mx-2">
            <div formarrayname="dempyoShubetsuCheckBox" class="col-lg-9 ng-untouched ng-pristine ng-valid dev-pc div-item-input input-list-h">
              <label>
                <input type="checkbox" id="dempyoShubetsu0" name="0" class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                  [checked]="displayColConfig.jusho"
                  (change)="onchangeDisplayColConfig('jusho')">
                <label class="me-3" for="dempyoShubetsu0">住所</label>
              </label>
              <label>
                <input type="checkbox" id="dempyoShubetsu1" name="1" class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                  [checked]="displayColConfig.tantosha"
                  (change)="onchangeDisplayColConfig('tantosha')">
                <label class="me-3" for="dempyoShubetsu1">拠点担当者</label>
              </label>
              <label>
                <input type="checkbox" id="dempyoShubetsu2" name="2" class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                  [checked]="displayColConfig.role"
                  (change)="onchangeDisplayColConfig('role')">
                <label class="me-3" for="dempyoShubetsu2">ロール</label>
              </label>
              <label>
                <input type="checkbox" id="dempyoShubetsu3" name="3" class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                  [checked]="displayColConfig.lastUpdated"
                  (change)="onchangeDisplayColConfig('lastUpdated')">
                <label class="me-3" for="dempyoShubetsu3">最終更新日</label>
              </label>
              <ng-container *ngFor="let system of systemList">
                <label>
                  <input type="checkbox" id="colConfig{{system.cd}}" name="{{system.cd}}" class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                    [checked]="displayColConfig['system' + system.cd]"
                    (change)="onchangeDisplayColConfig('system' + system.cd)">
                  <label class="me-3" for="colConfig{{system.cd}}">{{ system.nm }}</label>
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div id="search_form_buttons" class="d-flex flex-column flex-lg-row mx-2">
          <div class="ms-lg-1 order-lg-3">
            <button class="me-lg-2 dev-pc btn btn-lg btn-positive" type="button" [disabled]="!kyotenList.length"
            (click)="onsubmitEvent()">検索</button>
          </div>
          <div class="me-lg-auto order-lg-1">
            <button class="ms-lg-2 dev-pc btn btn-lg btn-negative" type="button" [disabled]="!kyotenList.length"
             (click)="clear.emit(); $event.stopPropagation()">クリア</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <hojin-search-modal [isShow]="isOpenHojinSearch" (clickSelect)="onHojinSelect($event)" (clickClose)="closeHojinSearchModal()">
  </hojin-search-modal>
  <kyoten-search-modal [isShow]="isOpenKyotenSearch" (clickSelect)="onKyotenSelect($event)" (clickClose)="closeKyotenSearchModal()">
  </kyoten-search-modal>
</div>
