import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TopPageService } from '../../toppage.component/toppage.service';
import { MamezoMessage, HojinSearchForm, HojinSearchResultType } from '../../toppage-types';

@Component({
  selector: 'hojin-search-modal',
  templateUrl: './hojin-search-modal.component.html',
})
export class HojinSearchModalComponent {
  @Input() isShow : boolean = false;
  @Output() clickSelect = new EventEmitter<string>();
  @Output() clickClose = new EventEmitter<string>();

  topPageService: TopPageService = inject(TopPageService);
  initSearchForm : HojinSearchForm = {
    keiyakuHojinCd: '',
    keiyakuHojinNm: '',
    keiyakuHojinKana: '',
    yubinNo: '',
    jusho: '',
    linesPerPage: 30,
    pageTo: 1,
  };
  linesPerPageList = [10, 30, 50, 100];
  searchForm: FormGroup;
  searchResult?: HojinSearchResultType;
  searchResultCount?: number;
  // isNoResultMessage = false;
  headerMessage = new MamezoMessage();

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group(this.initSearchForm);
  }

  onchangeLinesPerPage() {
    this.search(this.searchForm.value);
  }
  onclickSearch() {
    this.search(this.searchForm.value);
  }
  onSwitchPage(value: string) {
    this.searchForm.controls.pageTo.setValue(value);
    this.search(this.searchForm.value);
  }
  search(form: any) {
    this.searchResult = undefined;
    this.searchResultCount = undefined;
    this.headerMessage.hide();
    this.topPageService.searchHojin(form).subscribe({
      next: (res) => {
        this.searchResult = res;
        this.searchResultCount = this.searchResult.length > 0 ? this.searchResult[0].result_count : 0;
        if (this.searchResultCount === 0) this.headerMessage.setMessage("検索結果がありません").show();
        // 郵便番号の書式変換
        this.searchResult.forEach(hojin => {
          const regexp = hojin.yubin_no.match(/^(\d{3})(\d{4})$/);
          if (regexp) {
            hojin.yubin_no = regexp[1] + "-" + regexp[2];
          }
        });
      },
      error: (err) => {
        console.error(err);
        this.headerMessage.setMessage(err.error).show();
      }
    });
  }

  isConditionOpen: boolean = true;
  onclickAccordion() {
    this.isConditionOpen = !this.isConditionOpen;
  }

  onclickClear() {
    this.clear();
  }
  clear() {
    this.searchForm = this.formBuilder.group(this.initSearchForm);
    this.searchResult = undefined;
    this.searchResultCount = undefined;
    this.headerMessage.message = "";
    this.headerMessage.hide();
  }
  onclickSelect(value: {x_keiyaku_hojin_cd: string, keiyaku_hojin_nm: string, keiyaku_hojin_long_nm: string}) {
    this.clickSelect.emit(JSON.stringify(value));
    this.clear();
  }
  onclickClose() {
    this.clickClose.emit();
    this.clear();
  }
  onclickModalBackdrop() {
    this.clickClose.emit();
    this.clear();
  }
  onclickModalContent($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
