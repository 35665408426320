import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'projects/mamezo/src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { SearchCondition, SearchForm, SearchResultType, MailConfigRequestForm, HojinSearchForm, HojinSearchResultType, KyotenSearchForm, KyotenSearchResultType } from 'projects/mamezo/src/app/page/toppage/toppage-types';
const SERVER_URL = environment.BACKEND_BASE_URL;

const logger = environment.logger;

@Injectable()
export class TopPageService {
  httpClient: HttpClient = inject(HttpClient);
  authService: AuthService = inject(AuthService);
  token?: string;

  constructor() {
  }
  setToken(token: string) {
    this.token = token;
  }
  getToken() {
    return this.token;
  }

  getRefreshToken(callback?: (token?: string) => void) {
    logger.log("getRefreshToken")
    return this.authService.isAuthenticated$.subscribe((isLoggedIn) => {
      logger.log("isLoggedIn", isLoggedIn);
      if (!isLoggedIn) return;
      this.authService.user$.subscribe((user) => {
        logger.log("user", user);
        if (user === undefined || user === null) return;
        this.authService.getAccessTokenSilently({
          detailedResponse: true,
         }).subscribe((token) => {
           this.setToken(token.access_token);
           logger.log("getRefreshToken token", token);
          if (callback) callback(token.access_token);
        });
      });
    });
  }

  /** 検索条件の取得 */
  getSearchConditions = (): Observable<HttpResponse<SearchCondition>> => {
    const token = this.getToken();
    logger.log("getSearchConditions token", token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token,
      }),
      observe: 'response' as const,
      withCredentials: true,
    };
    logger.log("getSearchConditions", {httpOptions});
    // logger.log("getToken\n" + this.getToken());
    return this.httpClient.get<SearchCondition>(SERVER_URL + "api/search-conditions", httpOptions);
  };
  /** 検索処理 */
  search = (form: SearchForm): Observable<SearchResultType> => {
    const token = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
      withCredentials: true
    };
    logger.log({form})
    return this.httpClient.post<SearchResultType>(SERVER_URL + "api/search", form, httpOptions);
  };

  /** メール受信設定の登録 */
  registerMailConfig(sampleMailConfigForm: MailConfigRequestForm) {
    const token = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
      withCredentials: true
    };
    return this.httpClient.post<SearchResultType>(SERVER_URL + "api/registerMailConfig", sampleMailConfigForm, httpOptions);
  }
  /** 法人検索ダイアログ 検索処理 */
  searchHojin = (form: HojinSearchForm): Observable<HojinSearchResultType> => {
    const token = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
      withCredentials: true
    };
    logger.log({form})
    return this.httpClient.post<HojinSearchResultType>(SERVER_URL + "api/hojin/search", form, httpOptions);
  };
  /** 拠点検索ダイアログ 検索処理 */
  searchKyoten = (form: KyotenSearchForm): Observable<KyotenSearchResultType> => {
    const token = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
      withCredentials: true
    };
    logger.log({form})
    return this.httpClient.post<KyotenSearchResultType>(SERVER_URL + "api/kyoten/search", form, httpOptions);
  };
}
