<div id="backGround">
  <div id="floatingCirclesG">
    <div class="f_circleG" id="frotateG_01"></div>
    <div class="f_circleG" id="frotateG_02"></div>
    <div class="f_circleG" id="frotateG_03"></div>
    <div class="f_circleG" id="frotateG_04"></div>
    <div class="f_circleG" id="frotateG_05"></div>
    <div class="f_circleG" id="frotateG_06"></div>
    <div class="f_circleG" id="frotateG_07"></div>
    <div class="f_circleG" id="frotateG_08"></div>
  </div>
</div>
<!-- <div id="loading" class="modal" style="background-color: rgba(0, 0, 0, 0.25);">
  <div class="modal-inner">
    <div>読み込み中{{ loadingDot }}</div>
  </div>
</div> -->
